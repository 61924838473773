import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		//beforeEnter: AuthRequired,
		children: [
			{
				path: 'page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
			},
			{
				name: 'travelcase',
				path: '/page/app/travelcase',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/travelcase')
			},
			{
				name: 'modules',
				path: '/page/app/modules',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/modules')
			},
			{
				name: 'reports',
				path: '/page/app/reports',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/reports')
			},
			{
				name: 'customers',
				path: '/page/app/customers',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/customers')
			},
			{
				name: 'kilometer_reporting',
				path: '/page/app/kilometer_reporting',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/kilometer_reporting')
			},
			{
				name: 'booking_detail',
				path: '/page/app/booking_detail',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/booking_detail')
			},
			{
				name: 'users',
				path: '/page/app/users/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users')
			},
			{
				name: 'admin',
				path: '/page/app/admin',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/admin')
			},
			{
				name: 'bsp_merger',
				path: '/page/app/bsp_merger',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/bsp_merger')
			},
			{
				name: 'bank_xml',
				path: '/page/app/bank_xml',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/bank_xml')
			},
			{
				name: 'setup',
				path: '/page/app/setup',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/setup')
			},
			{
				name: 'airplus_converter',
				path: '/page/app/airplus_converter',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/airplus_converter')
			},
			{
				name: 'prism',
				path: '/page/app/prism',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/prism')
			},
			{
				name: 'savings',
				path: '/page/app/savings',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/savings')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
